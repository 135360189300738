import React from 'react'

import Anchor from '@/components/base/anchor/Anchor'

import { LinkProps } from './types'

const Link: React.FC<LinkProps> = ({ anchor, children }) => {
  return <Anchor {...anchor}>{children}</Anchor>
}

export default Link
